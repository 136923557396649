<template>
  <v-container
    style="margin: auto"
    class="blog fill-height justify-center align-center"
  >
    <h1 style="margin-bottom: 3%; margin-top: 3%">
      How to Cite Movies from DVDs, Streaming Services, etc. in APA format
    </h1>

    <v-row>
      <p>
        Just as you cite a book or magazine in your research papers, you have to
        cite the movies that have contributed to your position. To use the TV
        and movie quotes you’ll find on Pop Mystic in any kind of real research,
        you will need to cite its source. This article is a reference for APA
        style (7th Edition) citations for the quotes from movies and television
        shows you want to use to support your arguments. Much of the information
        presented here is a quick synopsis of the excellent and detailed
        information available at
        <a href="https://www.scribbr.com/category/apa-style">Scribbr.com </a>
      </p>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2" align="center" justify="center">
        <v-img
          class="mx-auto"
          src="../../assets/apa/apa-logo.jpg"
          contain
          max-width="150px"
        ></v-img> </v-col
      ><v-col cols="12" md="10" lg="10" xl="10">
        APA stands for American Psychological Association and is the format most
        commonly used in the social sciences and, well, psychology. Research
        papers in disciplines like sociology or library science are likely
        written in APA format. Though these disciplines are not as likely to
        cite movies and TV shows as those that use MLA format, it does come up.
        For example, imagine writing a paper on the psychology of
        <a href="https://en.wikipedia.org/wiki/Peter_Pan_syndrome"
          >Peter Pan Syndrome</a
        >
        without some great
        <a
          href="https://popmystic.com/searchResults?phrase=he%20grow&title=pan&startYear=1953&endYear=1953"
          >Peter Pan quotes</a
        >
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>Bibliography Citation</h2>
    </v-row>
    <v-row style="margin-bottom: 20px" align="center" justify="center">
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-img
          class="mx-auto"
          src="../../assets/apa/shaft-poster.jpg"
          contain
          max-width="200px"
        ></v-img>
      </v-col>
      <v-col cols="10" md="10" lg="10" xl="10">
        Format
        <v-card color="primary">
          <v-card-text style="padding-bottom: 0">
            Last name, Initials. (Director). (Year). <i>Movie title</i> [Film].
          </v-card-text>
          <v-card-text style="padding-top: 0; margin-left: 16px">
            Production Company
          </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text style="padding-bottom: 0">
            Parks, G. (Director). (1971). <i>Shaft</i> [Film].
          </v-card-text>
          <v-card-text style="padding-top: 0; margin-left: 16px">
            Shaft Productions.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>In-Text Citation</h2>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text> (Directorlastname, year, 00:00:00). </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            Early in the film, Sergeant Tom Hannon asks, “Hey, where the hell
            are you going, Shaft?” To which Shaft responds, “To get laid, where
            the hell you going?” (Parks, 1971, 00:08:42).
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-img
          class="mx-auto"
          src="../../assets/apa/shaft-get-laid.jpg"
          contain
          max-height="300px"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ApaMovie",
  data: () => ({
    paragraphs: [
      {
        text: "Just as you cite a book or magazine in your research papers, you have to cite the movies that have contributed to your position. To use the TV and movie quotes you’ll find on <Pop Mystic with link> in any kind of real research, you will need to cite its source. This article is a reference for APA style (7th Edition) citations for the quotes from movies and television shows you want to use to support your arguments. Much of the information presented here is a quick synopsis of the excellent and detailed information available at Scribbr.com <https://www.scribbr.com/category/apa-style/>",
        imageSrc: "src/assets/amazon-button1.png",
      },
    ],
  }),

  components: {},
};
</script>

<style scoped>
.blog {
  font-weight: 600;
  color: black;
}
h2 {
  text-decoration: underline;
  text-align: center;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: black;
}
.container {
  max-width: 900px;
}
@media (min-width: 1904px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 900px;
  }
}
</style>
